<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row>
        <b-col xl="6" lg="6" md="6">
          <user-info-card />
        </b-col>
        <b-col v-if="authUser.super_admin !== 1" xl="6" lg="6" md="6">
          <user-timeline-card />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";

import UserInfoCard from "./UserInfoCard.vue";
import UserTimelineCard from "./UserTimelineCard.vue";

// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'

// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import { mapGetters } from "vuex";
export default {
  name: "UserDetailsView",

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UserInfoCard,
    UserTimelineCard,

    // UserViewUserPlanCard,
    // UserViewUserTimelineCard,
    // UserViewUserPermissionsCard,

    // InvoiceList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
  },
};
</script>

<style>
</style>
