var render = function () {
  var _vm$userData, _vm$userData2, _vm$userData3, _vm$userData4, _vm$userData5, _vm$userData6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "User Timeline"
    }
  }, [_c('app-timeline', [_vm._l(_vm.userHistories, function (item) {
    return [(item === null || item === void 0 ? void 0 : item.activity) == _vm.JOINED ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "warning",
        "title": "On Boarded To Project X",
        "subtitle": _vm.formatDateTime(item === null || item === void 0 ? void 0 : item.created_at),
        "time": _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : _vm._e(), (item === null || item === void 0 ? void 0 : item.activity) == _vm.INITIAL_DESIGNATION ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "warning",
        "title": 'Assinged Designation: ' + (item === null || item === void 0 ? void 0 : item.value),
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.INITIAL_SALARY ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "warning",
        "title": 'Initial Salary ' + (item === null || item === void 0 ? void 0 : item.value) + ' BDT',
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.PROMOTED ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "success",
        "title": 'Promoted to ' + (item === null || item === void 0 ? void 0 : item.value),
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.DEMOTED ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "danger",
        "title": 'Demoted to ' + (item === null || item === void 0 ? void 0 : item.value),
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.SALARY_CHANGE ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "info",
        "title": 'Salary Updated to ' + (item === null || item === void 0 ? void 0 : item.value) + ' BDT',
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.TERMINATED ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "danger",
        "title": 'Terminated because ' + (item === null || item === void 0 ? void 0 : item.value) + '',
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.EMP_TYPE ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "info",
        "title": 'Employee Type Updated To ' + (item === null || item === void 0 ? void 0 : item.value) + '',
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : (item === null || item === void 0 ? void 0 : item.activity) == _vm.CUSTOM_LOG ? [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "variant": "primary",
        "title": item === null || item === void 0 ? void 0 : item.value,
        "time": item !== null && item !== void 0 && item.effective_date ? _vm.formatYear(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatYear(item === null || item === void 0 ? void 0 : item.created_at),
        "subtitle": item !== null && item !== void 0 && item.effective_date ? _vm.formatDate(item === null || item === void 0 ? void 0 : item.effective_date) : _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })] : _vm._e()];
  }), _c('app-timeline-item', {
    attrs: {
      "variant": "success",
      "title": "Joined Company",
      "time": (_vm$userData = _vm.userData) !== null && _vm$userData !== void 0 && _vm$userData.effective_date ? _vm.formatYear((_vm$userData2 = _vm.userData) === null || _vm$userData2 === void 0 ? void 0 : _vm$userData2.effective_date) : _vm.formatYear((_vm$userData3 = _vm.userData) === null || _vm$userData3 === void 0 ? void 0 : _vm$userData3.created_at),
      "subtitle": (_vm$userData4 = _vm.userData) !== null && _vm$userData4 !== void 0 && _vm$userData4.effective_date ? _vm.formatDate((_vm$userData5 = _vm.userData) === null || _vm$userData5 === void 0 ? void 0 : _vm$userData5.effective_date) : _vm.formatDate((_vm$userData6 = _vm.userData) === null || _vm$userData6 === void 0 ? void 0 : _vm$userData6.created_at)
    }
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }