var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [[_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('user-info-card')], 1), _vm.authUser.super_admin !== 1 ? _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('user-timeline-card')], 1) : _vm._e()], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }