var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AlertCircleIcon"
          }
        }), _c('span', [_vm._v("Genral")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" General Information ")])], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "PhoneCallIcon"
          }
        }), _c('span', [_vm._v("Contact")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Address Information ")]), _c('b-card-text', [_vm._v(" Family Information ")])], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v("Documents")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Docs Information ")]), _c('b-card-text', [_vm._v(" Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake. ")])], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "TrendingUpIcon"
          }
        }), _c('span', [_vm._v("Bank Account")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Bank Acc Infromation ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }