// export const userHistoryActivityTypeConstants = [
//   { name: "Joined", value: "joined" },
//   { name: "Initial_Designation", value: "initial_designation" },
//   { name: "Initial_salary", value: "initial_salary" },
//   { name: "Demotion", value: "demoted" },
//   { name: "Promotion", value: "promoted" },
//   { name: "Salary_Change", value: "salary_change" },
// ];

export const JOINED = "joined";

export const INITIAL_DESIGNATION = "initial_designation";

export const INITIAL_SALARY = "initial_salary";

export const DEMOTED = "demoted";

export const PROMOTED = "promoted";

export const SALARY_CHANGE = "salary_change";
export const TERMINATED = "terminated";
export const EMP_TYPE = "emp_type";
export const CUSTOM_LOG = "custom";
