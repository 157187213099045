<template>
  <b-row>
    <b-col md="12">
      <b-card title="User Timeline">
        <app-timeline>
          <template v-for="item in userHistories">
            <template v-if="item?.activity == JOINED">
              <app-timeline-item
                :key="item?.id"
                variant="warning"
                title="On Boarded To Project X"
                :subtitle="formatDateTime(item?.created_at)"
                :time="formatYear(item?.created_at)"
              />
            </template>
            <!-- "initial_designation" -->
            <template v-if="item?.activity == INITIAL_DESIGNATION">
              <app-timeline-item
                :key="item?.id"
                variant="warning"
                :title="'Assinged Designation: ' + item?.value"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
            <template v-else-if="item?.activity == INITIAL_SALARY">
              <app-timeline-item
                :key="item?.id"
                variant="warning"
                :title="'Initial Salary ' + item?.value + ' BDT'"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
            <template v-else-if="item?.activity == PROMOTED">
              <app-timeline-item
                :key="item?.id"
                variant="success"
                :title="'Promoted to ' + item?.value"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
            <template v-else-if="item?.activity == DEMOTED">
              <app-timeline-item
                :key="item?.id"
                variant="danger"
                :title="'Demoted to ' + item?.value"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
            <template v-else-if="item?.activity == SALARY_CHANGE">
              <app-timeline-item
                :key="item?.id"
                variant="info"
                :title="'Salary Updated to ' + item?.value + ' BDT'"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
            <template v-else-if="item?.activity == TERMINATED">
              <app-timeline-item
                :key="item?.id"
                variant="danger"
                :title="'Terminated because ' + item?.value + ''"
                :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
              />
            </template>
              <template v-else-if="item?.activity == EMP_TYPE">
                <app-timeline-item
                  :key="item?.id"
                  variant="info"
                  :title="'Employee Type Updated To ' + item?.value + ''"
                  :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                  :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
                />
              </template>
            <template v-else-if="item?.activity == CUSTOM_LOG">
                <app-timeline-item
                  :key="item?.id"
                  variant="primary"
                  :title="item?.value"
                  :time="item?.effective_date ? formatYear(item?.effective_date) : formatYear(item?.created_at)"
                  :subtitle="item?.effective_date ? formatDate(item?.effective_date) : formatDate(item?.created_at)"
                />
              </template>
          </template>

          <app-timeline-item
            variant="success"
            title="Joined Company"
            :time="userData?.effective_date ? formatYear(userData?.effective_date) : formatYear(userData?.created_at)"
            :subtitle="userData?.effective_date ? formatDate(userData?.effective_date) : formatDate(userData?.created_at)"
          />
        </app-timeline>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar, BRow, BCol } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  JOINED,
  INITIAL_DESIGNATION,
  INITIAL_SALARY,
  DEMOTED,
  PROMOTED,
  SALARY_CHANGE,
  TERMINATED,
  EMP_TYPE,
  CUSTOM_LOG,
} from "@/helpers/constant/userHistoryActivityTypeConstant";
export default {
  name: "UserTimelineCard",
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
  },
  data() {
    return {
      userHistories: {},
      userData: "",
      JOINED,
      INITIAL_DESIGNATION,
      INITIAL_SALARY,
      DEMOTED,
      PROMOTED,
      SALARY_CHANGE,
      TERMINATED,
      EMP_TYPE,
      CUSTOM_LOG,
    };
  },
  async created() {
    try {
      const [userGeneralData, userHistories] = await Promise.all([
        this.getUserGeneralData(),
        this.getUserHistory(),
      ]);

      const data = userHistories?.data?.data;
      this.userHistories = data;
      this.userData = userGeneralData?.data?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMMM Do YYYY");
      }
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMMM Do YYYY, h:mm a");
      }
    },
    formatYear(value) {
      if (value) {
        return this.$moment(value).format("YYYY");
      }
    },
    async getUserGeneralData() {
      return await this.$api.get(`api/users/${this.$route.params.id}`);
    },
    async getUserHistory() {
      return await this.$api.get(
        `api/user-history/user/${this.$route.params.id}`
      );
    },
  },
};
</script>

<style>
</style>
