<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="AlertCircleIcon" />
        <span>Genral</span>
      </template>

      <b-card-text> General Information </b-card-text>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="PhoneCallIcon" />
        <span>Contact</span>
      </template>

      <b-card-text> Address Information </b-card-text>
      <b-card-text> Family Information </b-card-text>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Documents</span>
      </template>

      <b-card-text> Docs Information </b-card-text>
      <b-card-text>
        Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie
        marshmallow caramels gingerbread jelly beans chocolate bar oat cake
        wafer. Chocolate bar danish icing sweet apple pie jelly-o carrot cake
        cookie cake.
      </b-card-text>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="TrendingUpIcon" />
        <span>Bank Account</span>
      </template>

      <b-card-text> Bank Acc Infromation </b-card-text>
    </b-tab>
  </b-tabs>
</template>

  <script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
  },
};
</script>
